import * as React from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import { adopt } from 'react-adopt';
import * as qs from 'query-string';
import classnames from 'classnames';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import { url, routes, path } from 'inkp-routes/public';
import { ORDER_STATUS_ENUM, SHIPPING_SPEED_ENUM, ORDER_EVENT_ENUM, Order, OrderEvent, ORDER_HOLD_REASONS } from 'inkp-order-sdk/types.g';
import {
  ORDER_STATUS_ENUM_MAPPING,
  ORDER_HOLD_ARTWORK_REASONS,
  ORDER_HOLD_REASON_MAP,
  formatDesignChangeLink,
  formatDesignChangeLinkWithEmail,
} from 'inkp-order-sdk/order';
import { prettyProductSize } from 'inkp-product-sdk/product';
import { SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/order';
import { isUserRegistered } from 'inkp-user-sdk/user';

// Utils
import {
  GET_MY_ORDER_BY_ID_QUERY,
  GET_ORDER_BY_ID_AND_EMAIL_QUERY,
} from '../../../util/orders';
import { LIST_ORDER_EVENTS_QUERY } from '../../../util/orderEvents';
import { getCurrentUser } from '../../../util/login';

// GTM helpers
import GTM from '../../../util/gtm';
import { GTMTypes } from '../../../interfaces/GTM';

// Components
import OrderDisplayer from 'inkp-components/dist/Components/OrderDisplayer';
import Modal from 'inkp-components/dist/Components/Modal';
import Notif from 'inkp-components/dist/Components/Notif';
import Loading from 'inkp-components/dist/Components/Loading';
import Button from 'inkp-components/dist/Components/Button';
import Alert from 'inkp-components/dist/Components/Alert';

const OrderComposed = adopt({
  queryOrderById: ({ render }: any) => (
    <Query query={GET_MY_ORDER_BY_ID_QUERY}>
      {(gqlQuOrderById: any) => render(gqlQuOrderById)}
    </Query>
  ),
  queryOrderByIdAndEmail: ({ render }: any) => (
    <Query query={GET_ORDER_BY_ID_AND_EMAIL_QUERY}>
      {(gqlQuOrderByIdAndEmail: any) => render(gqlQuOrderByIdAndEmail)}
    </Query>
  ),
})

interface RouteParams {
  orderId: string;
}

class GetOrderById extends React.Component<WithApolloClient<RouteComponentProps<RouteParams>>> {
  private renderOrderNotFound(orderId: string) {
    return (
      <div className="flex flex-col w-full px-1 md:w-container md:mx-auto">
        <Alert
          alerts={[
            {
              type: 'error',
              title: 'Failed to lookup order',
              content: `Could not find order ${orderId}`,
            },
          ]}
        />
      </div>
    )
  }

  onClearState = () => () => {
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
      state: {},
    });
  };

  renderLoadingOrError = (loading: boolean, error: boolean, orderId: string) => {
    if (loading) {
      return <Loading />;
    }
    if (error) {
      return this.renderOrderNotFound(orderId);
    }
  };

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const {
      match: {
        params: { orderId },
      },
      location,
    } = this.props;
    const message = _.get(location, 'state.message');
    const urlQueries = qs.parse(this.props.location.search) || {};
    const email = urlQueries.email;
    const user = getCurrentUser(this.props.client.cache);
    const _isUserRegistered = isUserRegistered(user);

    return (
      <React.Fragment>
        <Helmet>
          <title>Order Info</title>
        </Helmet>
        <Query query={GET_ORDER_BY_ID_AND_EMAIL_QUERY} variables={{ id: orderId, email}} skip={_isUserRegistered}>
          {({ loading: emailOrderLoading, error: emailOrderError, data: emailOrderData }: any) => (
            <Query query={GET_MY_ORDER_BY_ID_QUERY} variables={{ id: orderId }} skip={!_isUserRegistered}>
              {({ loading: userOrderLoading, error: userOrderError, data: userOrderData }: any) => (
                <Query query={LIST_ORDER_EVENTS_QUERY} variables={{ query: { orderIds: [orderId], names: [ORDER_EVENT_ENUM.ON_HOLD] }}}>
                  {({ loading: eventsLoading, error: eventsError, data: eventsData }: any) => {

                    const loading = emailOrderLoading || userOrderLoading || eventsLoading;
                    const error = emailOrderError || userOrderError || eventsError;
                    if (loading || error) return this.renderLoadingOrError(loading, error, orderId);

                    let holdEvent: OrderEvent | null = null;
                    let orderDetails: any = null;
                    const dictionary: any = {};
                    const order: Order = _.get(userOrderData, 'orderByIdAndUser') || _.get(emailOrderData, 'orderByIdAndEmail');

                    if (!orderId || !order) return this.renderOrderNotFound();
                    if (order) {
                      const {
                        status,
                        quote: { items },
                        billingAddress,
                        shippingAddress,
                        features,
                      } = order;

                      orderDetails = {
                        status,
                        deliveringDate: new Date(order.dueAt),
                        orderCreationDate: new Date(order.orderedAt),
                        total: order.quote.amounts.total,
                        subTotal: order.quote.amounts.subtotal,
                        tax: order.quote.amounts.tax,
                        showDetail: true,
                        orderItemGroups: [],
                        requiresCustomerApproval: false,
                        shiping: {
                          description: `Shipping (${
                            SHIPPING_SPEED_ENUM_MAPPING[order.quote.shipping.speed as SHIPPING_SPEED_ENUM]
                          } Days Standard)`,
                          cost: order.quote.amounts.shipping,
                        },
                        userInfo: {
                          contactInfo: {
                            name: billingAddress.name || '',
                            email: billingAddress.email || user.email,
                            organization: billingAddress.email || '',
                          },
                          paymentInfo: {
                            cardNumber: (order.quote.payment && order.quote.payment.last4) || 1234,
                            cardType: (order.quote.payment && order.quote.payment.processorType) || 'visa',
                          },
                          billingAddress: {
                            name: billingAddress.name || '',
                            company: billingAddress.company || '',
                            address1: billingAddress.address1 || '',
                            address2: billingAddress.address2 || '',
                            city: billingAddress.city || '',
                            stateAndZip: `${billingAddress.state}, ${billingAddress.zip}` || '',
                            zip: billingAddress.zip || '',
                            country: billingAddress.country || '',
                            phone: billingAddress.phone || '',
                          },
                          shipingAddress: {
                            name: shippingAddress.name || '',
                            company: shippingAddress.company || '',
                            address1: shippingAddress.address1 || '',
                            address2: shippingAddress.address2 || '',
                            city: shippingAddress.city || '',
                            stateAndZip: `${shippingAddress.state}, ${shippingAddress.zip}` || '',
                            zip: shippingAddress.zip || '',
                            country: shippingAddress.country || '',
                            phone: shippingAddress.phone || '',
                          },
                        },
                      };

                      if (eventsData && eventsData.listOrderEvents) {
                        holdEvent = _.sortBy(eventsData.listOrderEvents, (orderEvent: OrderEvent) => {
                          return -1 * new Date(orderEvent.createdAt).getTime();
                        })[0];
                      }

                      if (features) {
                        orderDetails.requiresCustomerApproval = features.requiresCustomerApproval;
                      }

                      items.forEach((item: any) => {
                        const {
                          designId,
                          product: { product },
                          amounts,
                          tracking,
                          status,
                        } = item;
                        const key: string = (tracking && tracking.number) || 'na';
                        const trackingLink = tracking && tracking.link;
                        let resubmitArtworkLink = null;
                        let holdReasons = null;
                        let colorProductItem: any = item.product.productItem;
                        if (!item.product.productItem) {
                          colorProductItem = { color: item.product.product.colors[0].name, size: 'lrg' };
                        }
                        const productItemKey = `${item.product.product.id}-${designId}-${colorProductItem.color}`;
                        const templateIdentifier: any = item.product.product.designTemplates.find((designTemplate: any) => {
                          return designTemplate.side === item.design.sides[0].name;
                        });

                        const designColorHex = product.colors.find((color: any) => {
                          return color.name === colorProductItem.color;
                        }).hex;
                        let mockupUrl: string = product.image.url;
                        if (templateIdentifier) {
                          mockupUrl = url('mockup', mockupRoutes.mockup, {
                            designId: item.designId,
                            templateIdentifier: templateIdentifier.id,
                            color: designColorHex,
                            side: item.design.sides[0].name,
                            size: 'medium',
                          });
                        }

                        const rejectedDesign = holdEvent && holdEvent.data && _.find(holdEvent.data.rejectedDesigns, { designId });
                        if (rejectedDesign && rejectedDesign.reasons) {
                          holdReasons = rejectedDesign.reasons.map((reason: ORDER_HOLD_REASONS) => { return ORDER_HOLD_REASON_MAP[reason] });
                          const artworkHoldReasons = _.filter(rejectedDesign.reasons, (reason) => {
                            return ORDER_HOLD_ARTWORK_REASONS.includes(reason);
                          });
                          if (artworkHoldReasons.length > 0) {
                            resubmitArtworkLink = _isUserRegistered ?
                              formatDesignChangeLink(order, designId) :
                              formatDesignChangeLinkWithEmail(order, designId, email);
                          }
                        }
                        if (dictionary[key]) {
                          if (dictionary[key][productItemKey]) {
                            dictionary[key][productItemKey].quantity += Number(item.product.quantity);
                            dictionary[key][productItemKey].subtotal += Number(amounts.subtotal);
                            dictionary[key][productItemKey].productSizes.push({
                              name: prettyProductSize(colorProductItem.size),
                              quantity: item.product.quantity,
                            });
                          } else {
                            dictionary[key][productItemKey] = {
                              imageSrc: mockupUrl,
                              productName: product.name,
                              shareableName: item.design.shareable.name,
                              color: colorProductItem.color,
                              quantity: Number(item.product.quantity),
                              subtotal: Number(amounts.subtotal),
                              status,
                              trackingLink,
                              showStatus: true,
                              holdReasons,
                              resubmitArtworkLink,
                              productSizes: [
                                { name: prettyProductSize(colorProductItem.size), quantity: item.product.quantity },
                              ],
                            };
                          }
                        } else {
                          dictionary[key] = {
                            [productItemKey]: {
                              imageSrc: mockupUrl,
                              productName: product.name,
                              shareableName: item.design.shareable.name,
                              color: colorProductItem.color,
                              quantity: Number(item.product.quantity),
                              subtotal: Number(amounts.subtotal),
                              status,
                              trackingLink,
                              showStatus: true,
                              holdReasons,
                              resubmitArtworkLink,
                              productSizes: [
                                { name: prettyProductSize(colorProductItem.size), quantity: item.product.quantity },
                              ],
                            },
                          };
                        }
                      });

                      if (order.status.toUpperCase() === ORDER_STATUS_ENUM.PENDING_CUSTOMER_APPROVAL) {
                        orderDetails.onApproveUrl = path(routes.app.account.artworkApproval, {orderId, email});
                      }

                      orderDetails.orderItemGroups = Object.keys(dictionary).map((key: string) => {
                        return { key, items: Object.values(dictionary[key]) };
                      });
                    }

                    return (
                      <React.Fragment>
                        <div className="w-full md:w-container md:mx-auto">
                          <div className="d-ib px-p5 w-1/2 ta-left mt-p75 md:px-0">
                            {_isUserRegistered ?
                              <Link
                                to={`${routes.app.account.myAccount}?tab=Orders`}
                                className={'color-blue flex items-center'}
                              >
                                <i className="d-ib mdi mdi-chevron-left color-blue fs-xl" />
                                <h3 className="d-ib fs-md align-text-bottom">Back to Orders</h3>
                              </Link>
                              :
                              <Link
                                to={routes.app.account.trackOrder}
                                className={'color-blue flex items-center'}
                              >
                                <i className="d-ib mdi mdi-chevron-left color-blue fs-xl" />
                                <h3 className="d-ib fs-md align-text-bottom">Back</h3>
                              </Link>
                            }
                          </div>
                          <div className="flex items-center px-1 mt-p75 mb-1 md:px-0 md:mt-1 md:mb-2">
                            <div className="flex-grow color-navy fs-xl">
                              <span className="fw-extra-bold mr-p5">Order</span>{orderId}
                            </div>
                            <div className="flow-none">
                              <Link to={path(routes.app.order.invoice, { orderId, email })} target="_blank">
                                <Button corner="full" color="inverse" size="lg">
                                  Download Invoice
                                </Button>
                              </Link>
                            </div>
                          </div>
                          {!loading && !error ? (
                            <div className="mb-7 px-1 md:px-0">{orderDetails && <OrderDisplayer {...orderDetails} />}</div>
                          ) : null}
                        </div>
                        {message && (
                          <Modal width="687px" position="start" padding="px-1 pt-4p5 md:pt-7" overlayColor="">
                            <Notif type="success" onClose={this.onClearState()}>
                              {message}
                            </Notif>
                          </Modal>
                        )}
                      </React.Fragment>
                    );
                    }}
                  </Query>
                )}
            </Query>
          )}
        </Query>

      </React.Fragment>
    );
  }
}

export default withApollo(GetOrderById);
